.language-select{
    position: fixed;
    z-index: 99;
    right: 0%;
    top: 30%;
    padding: 1rem;
    background-color: white;
    border-radius: 10px 0 0 10px ;
    border: none;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    
}


.option-language { 
    transition: 1s linear;
    list-style: none;
    padding: 1rem;
    border-radius: 0px 0 0 10px;
    border: none;
    background-color: white;
    
}


.option-language:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}


@media (max-width: 768px) {
    .language-select { 
        padding: 0.5rem;
    }
}