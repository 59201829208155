.person-card {
    width: 80%;
    padding: 2rem;
    border-radius: 25px;
}

.person-card h4 {
    font-size: 1.5rem;
    font-weight: 800;
    padding:1rem 0
}

.person-card img {
    height: 65px;
}

.person-card li {
    margin: 0.5rem 0.2rem;
    font-size: 1em;
    font-weight: 600;
    list-style: " >  ";
}

