.dropdown {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
  }
  
  .dropdown-toggle {
    background-color: transparent;
    font-family: 'Maison Neue Bold';
    font-size: 18px;
    padding: 1rem 2.5rem 0 0;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
   
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color:#ffffff80;
    transition: 2s linear;
    padding: 0px;
    z-index: 2;
    list-style: none;
    box-sizing: border-box;
    
  }
  
  .dropdown li {
    
    margin: 0em 0.5em;
    transition: 1s linear;
    padding: 1rem!important;
  }

  .dropdown li:hover {
    background-color: rgb(86, 113, 113, 0.5);
    
    transition: 2s linear;
  }


@media (max-width: 950px) {
  .dropdown-toggle {
    padding: 0;
    color: white
  }
  .dropdown li {
    
    margin: 0;
    transition: 1s linear;
    padding: 0;
    background-color: transparent;
    overflow: scroll;
  }
}