.navbar {
    background-color: #FCD700;
    justify-content: space-between;
    max-width: 100%;
    padding: 44px 0;
    display: flex;
    box-sizing: border-box;
    position: sticky;
    top:0;
    z-index: 99;
    transition: background-color 0.3s ease-in-out;
    
}

.navbar.scrolled {
    background-color: rgba(252, 215, 0, 0.8);
  }

.navbar img {
    display: flex;
    margin-left: 105px;
    
}

.web-navmenu li {
    display: inline-flex;
    list-style: none;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    align-items: center;
}

.menu-hamburguer {
    display: none;
}
@media (max-width: 950px) {
    .web-navmenu li {
        display: none;
    }
    .navbar img {
        margin-left: 0;
        

    }
    .menu-hamburguer {
        display: inline-block;
    }
    .navbar {
        display: flex;
        flex-direction: row;
        padding: 2rem;
        
        z-index: 99;
        /* width: 100%; */

    }
  }