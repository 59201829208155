.partner-section {
    background-color: white;
    border-radius: 200px 0 0 0;
    background-color: var(--background-color);
}

.partner-section h2 {
    
    font-size: 3em;
    font-family: 'Maison Neue Extra Bold';
    display: flex;
    justify-content: center;
    padding: 3rem 1.5rem;
    
}

.partner-section p {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0.5rem;

}


.partner-logo ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5rem 2rem; 
    padding: 7rem; 

}



.partner-logo li img {
    max-width: 100%;
    display: flex;
}

.partner-joinus {
    background-color: var(--yellow-color);
    border-radius: 0px 0 200px 0 ;
    padding: 5rem;
    text-align: center;

}



@media  (max-width: 768px) {
    .partner-section {
        
        border-radius: 80px 0 0 0;
        
    }

    .partner-logo ul {
        
        grid-template-columns: repeat(1, 1fr);
        
    
    }

    .partner-logo li  {
        display: flex;
        justify-content: center;
    }
}