:root {
  --font-use: 'Maison Neue Light';
  --background-color: #F5F5F5;
  --yellow-color: #FCD700;
}
@font-face {
  font-family: 'Maison Neue Bold';
  src: url("/public/font/MaisonNeueBold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: 'Maison Neue Mono';
  src: url("/public/font/MaisonNeueMono.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Maison Neue Light';
  src: url("/public/font/MaisonNeueLight.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: 'Maison Neue Extra Bold';
  src: url("/public/font/MaisonNeueExtendedExtraBold.ttf") format("truetype");
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  font-family: var(--font-use), sans-serif;
  scroll-behavior: smooth;

}
body {
  background-color: white; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
p {
  font-size: 1.2em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  text-decoration: none !important;
}