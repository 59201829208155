footer {

    background-color: black;
    border-radius: 0 250px 0 0;
    
}

.footer-column {
    
    color: white!important;
    padding: 80px 80px 30px 80px;
    display: grid;
    font-weight: 900;
    grid-template-columns: repeat(3,1fr);
    gap: 30px
}
.footer-column img {
    display: flex;

    
}
.footer-column a {
   text-decoration: none;
   color: white;
    
}
.footer-column h4 {
   font-weight: 900;
   padding-bottom: 2rem;
}
.footer-column li {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    
}



footer p {
    color: white;
    text-align: center;
    padding: 3em 0 
}
.social-media-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding-bottom: 3rem;
    border-bottom: 2px solid white;
}
.social-media-footer li {
    display: inline;
    padding: 1rem;
}
.social-media-footer img {
    display: inline;
}

.btn-footer {
    font-size: 1rem!important;
    margin: 2rem 0 0rem 0;
    padding: 1rem!important;
    width: 40%;
    justify-content: center;
}

@media (max-width: 767px) {
    .footer-column {
        border-bottom: 2px solid white;
        color: white!important;
        padding: 80px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        /* display: grid;
        font-weight: 900;
        grid-template-columns: repeat(1,1fr);
        gap: 30px */
    }
    footer {

        
        border-radius: 0 80px 0 0;
        
    }

    footer p {
        color: white;
        text-align: center;
        padding: 5rem 2rem 
    }
}