.banner-company {
    background-image: url('/public/image/company_background.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 65vh;
    display: flex;
    align-items: center;
    padding: 5rem;
    border-radius: 0 0 200px 0;
    color: white;
    
}


.banner-company div {
    max-width: 50%;

}
.banner-company h1 {
    padding-bottom: 2rem;
    font-size: 3rem;
    font-weight: 900;
}

.banner-company p {
    padding-bottom: 1rem;
}


@media (max-width: 968px) {
    .banner-company div {
        max-width: 100%;
    
    }
    .banner-company h1 {
        font-size: 1.5rem;
        max-width: 100%; 
    }
    .banner-company {
        background-image: url('/public/image/banner_company_mobile.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 80vh;
        display: flex;
        align-items: flex-end;
        
        padding:2rem;
        border-radius: 0 0 80px 0;
        color: white;
        
    }
}