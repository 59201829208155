.card {
    width: 367px;
    height: 323px;
    perspective: 1000px;
    color:white;
    margin: 0 ;
    max-width: 100%;
    
    
}

.card-inner {
position: relative;
width: 100%;
height: 100%;
transform-style: preserve-3d;
transition: transform 1s;
}

.card:hover .card-inner {
transform: rotateY(180deg);
}

.card-face {
position: absolute;
width: 100%;
height: 100%;
backface-visibility: hidden;


}

.card-front {
z-index: 2;
transform: rotateY(0deg);
display: flex;
flex-direction: column;
align-items: center; /* Centralizar na vertical */
text-align: center; /* Centralizar horizontalmente */


}

.card-front div {
    /* margin: 2em;
    padding: 2.5em; */
    border-radius: 50%;
}

.card-container {
    width: 15rem;
    height: 15rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px;
}

.card-front img {
    display: block;
    text-align: center;
    justify-content: center;
    align-content: center;
   
}
.card-front p {
    display: flex;
    text-align: center;
    justify-content: center;
    font-family: 'Maison Neue Bold';
   


    
}

.card-back {
transform: rotateY(180deg);
border-radius: 10px;
display: flex;
flex-direction: column;
color: black;
padding-bottom: 2rem;
}
.card-back img {
    width: 44px;
    height: 44px;
    margin-top: 1rem;
    padding-left: 30px;
}

.card-back h4 {
    font-size: 1rem;
    font-weight: 900px;
    padding: 1rem 30px;
}
.card-back p {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 30px;
    
}

@media (max-width: 1024px) {
    .card {
        max-width:60%;
    }

}


@media (max-width: 470px) {
    .card {
        max-width:70%;
    }

}