.upside-corner {
    position: relative;
    width: 300px;
    height: 200px;
    margin: 0;

}

.upside-corner img {
    position: absolute;
    top: 0;
}

@media (max-width: 950px) {


}
