.team-main {
    background-color: #4863F7;
}

.team-main-section {
    border-radius: 0 200px 0 0 ;
    background-color: white;
}
.team-cards-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    max-width: 100%;
    
}
.team-cards-content {
    
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    scale: 0.9!important;
    gap: 20px;
    
}

@media (max-width:1024px) {
    .team-cards-content {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width:950px) {
    .team-cards-content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width:650px) {
    .team-cards-content {
        grid-template-columns: repeat(1, 1fr);
    }
}