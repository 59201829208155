.navmenu li {
    display: inline-block;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.navmenu li a {
    text-decoration: none;
    color: rgb(10, 10, 10);
    text-decoration: none;
    font-family: 'Maison Neue Bold';

    
}


