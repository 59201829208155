.company-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem;
    gap: 5rem;
}


.company-cards-first {
    display: grid;
    grid-template-rows: auto; /* Duas linhas de altura automática */
    grid-template-columns: repeat(3, 1fr); /* Três colunas com largura igual (1fr) */
    gap: 20px; /* Espaçamento entre os itens do grid */
    align-items: center;
    text-align: center;
    justify-content: center;
    
}
.company-cards-second {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Três colunas com largura igual (1fr) */
    gap: 20px; /* Espaçamento entre os itens do grid */

    
    
}

@media (max-width: 968px) {
    .company-cards-first { 
        grid-template-columns: repeat(1, 1fr);
    }
    .company-cards-first div { 
        max-width: 100%;
    }
    .company-cards-second { 
        grid-template-columns: repeat(1, 1fr);
    }
    .company-cards-second div { 
        max-width: 100%;
    }

}