/* Modal fixo na tela */
.modal-popup {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
  }
  
  /* Botão de fechar no canto superior direito do banner */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .close-button:hover {
    background: red;
    color: white;
  }
  
  /* Imagem do banner responsiva */
  .banner-popup {
    width: 65vw; /* 45% da largura da tela */
    max-width: 800px; /* Define um tamanho máximo para telas grandes */
    height: auto;
    border-radius: 8px;
  }

