.voluntary-modalities {
    max-height: 100%;
}
.voluntary-container {
    display: flex;
    text-align: center;
    justify-content: center;
    
}

.voluntary-modalities h2 {
    text-align: center;
    font-size: 2.5rem;
    font-family: 'Maison Neue Bold';
}


.voluntary-cards {
    display: grid;
    grid-column: repeat(3, 1fr);
    list-style: none;
}

.voluntary-cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 150px ;
    padding: 100px 100px 100px 80px;
}

.btn-voluntary {
    padding: 2rem;
    display: flex;
    font-size: 1.5rem;
    text-align: center;
    border: none;
    background-color: var(--yellow-color);
    font-weight: 700;
    border-radius: 80px;
    margin-bottom: 10vh;
    cursor: pointer;
}


@media (max-width: 960px) {
    .voluntary-cards
     {
        grid-template-columns: repeat(2,1fr);
        max-width: 100%;
    }
    
}

@media (max-width: 765px) {
    .voluntary-cards
     {
        grid-template-columns: repeat(1,1fr);
        max-width: 100%;
        justify-content: center;
    }

    .btn-voluntary {
        font-size: 1rem;
        max-width: 90%;
    }
}