.banner-person {
    background-image: url('/public/image/voluntario.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 65vh;
    display: flex;
    align-items: center;
    padding: 5rem;
    border-radius: 0 0 200px 0;
}

.banner-person h1 {
    color: white;
    font-weight: 900;
    font-size: 3.5rem;
    max-width: 50%; 
}



@media (max-width: 765px) {
    .banner-person h1 {
        font-size: 2rem;
        max-width: 100%; 
    }
    .banner-person {
        align-items: flex-end;
        background-image: url('/public/image/voluntario_banner_mobile.png');
    }

}