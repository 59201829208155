.aboutcontent  {
    display: inline-block;
    
    justify-content: center;
    margin-top: 100px;
    
    /* background-color: blue!important ; */
   
}
.aboutcontent p{
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    text-align: justify;
    font-weight: 400;
    max-width: 100%;
}
.aboutcontent span{
    margin-top: 5em;
    font-weight: 800;

}
.aboutcontent img {
    margin-bottom: 2em;
    max-width: 80%;
}


@media (max-width: 1024px) {
    .aboutcontent  {
        
        margin-top: 0px;
        
        /* background-color: blue!important ; */
       
    }
    .aboutcontent img {
        margin-bottom: 2em;
        max-width: 100%;
    }
    .aboutcontent p{
        
        max-width: 100%;
    }

}


@media (max-width: 950px) {

    .aboutcontent span{
        display: none;
    
    }
}