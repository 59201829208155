

.team-cards {
    background-color: black;
    border-radius: 20px;
    color: white;
    padding: 2rem;
    max-width: 100%;
}

.team-more-info {
    border-radius: 50%;
    padding: 0.5em;
    background-color: var(--yellow-color);
    font-weight: 900;
    width: 2rem;
    max-width: 100%;
    
    
}

.team-first-content {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    gap: 1rem;
    max-width: 100%;
}

.team-cards img,
.team-cards h1,
.team-cards span 
{
    max-width: 100%;
}

.modal-overlay {
    background-color: white;
    position: absolute;
    color: black;
    border-radius: 20px;
    box-shadow: 0px 10px 010px rgba(0, 0, 0, 0.2);
    max-width: 25vw;
    border-bottom: 5px solid #fad700;
    z-index: 99;
    
    
    
}


.modal-btn {
    color: black
}

.modal {
    display: flex;
    flex-direction: column;
    padding: 2rem;
   
   
    
    
}

.modal img {
    width: 100%;
    max-width: 252px;
    overflow-wrap: normal;
}
.modal h2 {
    font-size: 1.5rem;
    padding: 1rem 0;

}
.person-description {
    max-width: 100%;
    overflow-y: scroll;
}

.linkedin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.btn-team span {
    margin:  0 0.5rem 0 0;
}

.btn-team {
    margin-top: 1rem;
    padding: 1rem 3rem;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

@media (max-width: 768px) {
    .modal-overlay {
        max-width: 100vw;
}
}