.project-card {
    color: black;
    border-bottom: 1px solid black;
    max-width: 100%;
}


.project-card p {
    margin-top: 2em;
    padding-bottom: 2em;
}

.project-card div {
    height: 120px;
}


@media (min-width: 310px) and (max-width: 767px) {
    .project-card li {
        max-width: 30%;
    
    }
}