.company-text{
    color: white;
    background-color: black!important;
}

.company-equivalents-cards{
    background-color: var(--yellow-color);
    border-radius: 0 0 0 200px;
}
.company-esg-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    align-items: center;
}

.company-esg-container img {
    margin-bottom: -1.5rem;
    max-width: 60%;
    z-index: 1;
}
.company-esg-card {
    padding: 2.5rem;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    
}

.company-esg-card p {
    
    max-width: 70%;
    font-family: 'Maison Neue Bold';
    margin-bottom: 1rem;
}

.company-esg-card span {
    font-weight: 900;
}
.company-esg-text-container{
    width: 100%;
}

.btn-company {
    background-color: black!important;
    color: white;
    margin-top: 5rem;
    font-size: 1rem!important;

}

@media (max-width: 968px) {
    .company-text{
        border-radius: 20px;
    }
    .company-text h3 {
        font-size: 1.5rem!important;
    }
    .btn-company {
        padding: 1rem!important;
        max-width: 100%!important;
    }
}