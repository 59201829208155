.about{
    box-sizing: border-box;
}
.about-full-content {
    padding: 0 90px;
}
.about-first-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Cria 3 colunas de tamanho igual */
    grid-gap: 40px; /* Define o espaçamento entre as colunas */
  }
.map {
    margin-top: 1em;
    box-sizing: border-box;
   
}

.map h2 {
    margin-bottom: 2em;
    text-align: center;
}

.map img {
    max-width: 100%;

}

.map span {
    display: none;
}


.about-second-content {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Cria 3 colunas de tamanho igual */
    grid-gap: 20px; /* Define o espaçamento entre as colunas */
}
.about-second-content  img{
    max-width: 100%;
}

.downside-corner {
    position: relative;
    width: 261px;
    height:261px;
    margin: 0;
    z-index: 1;
}

.downside-corner img {
    position: absolute;
    left: 0;
    
}

@media (max-width: 950px) {
    .about-first-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Cria 3 colunas de tamanho igual */
        grid-gap: 40px; /* Define o espaçamento entre as colunas */
      }
    .about-second-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Cria 3 colunas de tamanho igual */
        grid-gap: 40px; /* Define o espaçamento entre as colunas */
        padding-bottom: 0rem;
        justify-items: center;
      }

    .about-second-content img{
        max-width: 100%;
        display: flex;
        align-items: center;
        
    }
    .downside-corner {
        width: 80px;
        height: 80px;
        margin: 0;
    
    }
    .downside-corner img {
        width: 80px;
        height: 80px;
        margin: 0;
    
    }
    .upside-corner img {
        width: 80px;
        height: 80px;
        margin: 0;
    
    }
    .upside-corner {
        position: relative;
        width: 80px;
        height: 80px;
        margin: 0;
    
    }

    .map span {
        margin-top: 5em;
        display: inline-flex;
        font-weight: 800;
    }

}

@media (max-width: 1025px) {
    .about-second-content img{
        max-width: 80%;
    }
    .about-first-content {
        margin-top: 2rem;
        
    }
}

@media (min-width: 1026px) and (max-width: 1430px) {
    .about-second-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Cria 3 colunas de tamanho igual */
        grid-gap: 40px; /* Define o espaçamento entre as colunas */
    }
}