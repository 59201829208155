.esg-text {
    background-image: url(/public/image/esg_background.png);
    background-size: cover;
    padding: 7rem 5rem;
    border-radius: 0 0 200px 0;
}

.esg-text h3 {
    font-size: 3rem;
    color: var(--yellow-color);
    font-family: 'Maison Neue Bold';
    font-weight: 900;
    padding: 2rem 0;
}

.esg-text p {
    color: white;
    padding: 1rem 0;
    font-weight: 500;
    max-width: 90%;
}
.esg-section {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    background-color: white;
}

.esg-stamp {
    display: flex;
    align-items: center;

}
.esg-container {
    width: 100%;
    margin: 0;
    background-color: var(--yellow-color);
    height: 50%;
    display: flex;
    
    align-items: center;

}
.esg-container div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-left: -10%;
    max-width: 100%;
}

.esg-container img {

    max-width: 100%;
}


@media (max-width: 1024px) {
    .esg-stamp{
        justify-content: center;
    }
    .esg-section {
        grid-template-columns: repeat(1, 1fr);
    }

    .esg-text {
        padding: 7rem 1rem;
        border-radius: 0 0 80px 0;
    }
    .esg-container {
        width: 50%;
        height: 100%;
    }
    .esg-container div {
        grid-template-columns: repeat(1, 1fr);
        margin-left: 0;
        margin-top: -20%;
    }
}