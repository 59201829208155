.project-container {
    background-color: var(--yellow-color) ;
}


.project-content {
    background-color: white;
    border-radius: 0 0 250px 0;
}

.project-content h2 {
    font-size: 3em;
    font-family: 'Maison Neue Extra Bold';
    text-align: center;
    margin: 5rem;
}


.project-content ul {
    padding: 0 50px;
}
.project-content li {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 40px;
    padding: 30px;

}


@media (min-width: 768px) and (max-width: 1300px) {
    .project-content li {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
        padding: 30px;
    
    }
}
@media (max-width: 767px) {
    .project-content li {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;
        padding: 0px;
        max-width: 100%;
        
    
    }
    .project-content {
        background-color: white;
        border-radius: 0 0 80px 0;
        overflow: hidden;
    }

    .project-content {
        background-color: white;
        border-radius: 0 0 80px 0;
    }
}