.hamburger-menu {
  position: relative;
  overflow: hidden;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #000;
  margin-bottom: 6px;
  transition: 0.5s;
}

.open .menu-icon span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.open .menu-icon span:nth-child(2) {
  opacity: 0;
}

.open .menu-icon span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  z-index: 1;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.menu-items {
  list-style: none;
  padding: 0;
  margin: 5rem;
}

.menu-items li {
  margin-bottom: 10px;
  z-index: 1;
  visibility: visible;
}

.menu-items li  {
  width: 200px;
  height: 40px;
  color:white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;

}


.menu-items li a {
  color: white;
  text-decoration: none;
  font-weight: 800;

}

.menu-items li div {
  padding: 0;
}