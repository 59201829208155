.person-donation-main {
    background-color: rgb(255, 255, 255);
}


.person-text-yellow {
    background-color: var(--yellow-color);
    border-radius: 0 200px 0 0  ;
    padding: 3rem;
    display: flex;
    justify-content: center;
    max-width: 100%;
}


.person-text-yellow h3 {
    text-align: center;
    font-size: 2.5rem;
    max-width: 50%;
    font-family: 'Maison Neue Bold';

}

.person-cards-content {
    background-color: black;
    border-radius: 0 0 0 200px ;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
   
    
    
}

.person-cards  {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 150px ;
    padding: 100px 100px 100px 80px;
}


@media (max-width: 960px) {

    .person-cards{ 
        grid-template-columns: repeat(1,1fr);
        
        justify-items: center;
    }

    .person-text-yellow h3 {
        
        font-size: 2rem;
        max-width: 70%;
        
    
    }
}

@media (max-width: 765px) {
    .person-cards  {
        
        padding: 100px 0;
    }
}