.card-join {
    width: 515px;
    height: 388px;
    max-width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 1s linear, background-color 0.3s;
}

.card-join:hover {
    transform: translateY(-5px);
    background-color: rgba(0, 0, 0, 0.8);
    
    width: 615px;
    height: 488px;
    
    
}

.card-content {
position: absolute;
bottom: 0;
left: 0;
right: 0;
padding: 10px;
background-color: rgba(0, 0, 0, 0.2);
border-radius: 0 0 25px 25px;


}



.card-text {
color: #fff;
margin: 0;
border-bottom: 1px solid white;
display: inline-block;
font-weight: 800;
padding: 1rem 0 ;
}


@media (max-width: 767px) {
    .card-join {
        width: 315px;
        height: 488px;
    }
}