.JoinUs-section {
    background-color: white;
}

.JoinUs-section h2 {
    
    font-size: 3em;
    font-family: 'Maison Neue Extra Bold';
    display: flex;
    justify-content: center;
    padding: 5rem;
    
}

.join-content {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding:0 5em 8em 0;
    justify-items: center;

}

@media (min-width:768px) and (max-width: 1024px) {


    .join-content {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 40px;
        max-width:100%;
        
    
    }
}


@media (max-width: 767px) {
    .join-content {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 40px;
        padding: 0 0 8rem 0;
        
    
    }

    .JoinUs-section h2 {
    
        font-size: 3rem;
        font-family: 'Maison Neue Extra Bold';
        display: flex;
        justify-content: center;
        padding: 2rem;
        
    }
}